import { OptionalType, hasValue } from '@digital-magic/ts-common-utils'
import { AllowedAction, TranslatedString } from '@api/endpoints/types'

const hasAllowedAction =
  (action: AllowedAction) =>
  (allowedActions: OptionalType<ReadonlyArray<AllowedAction>>): boolean =>
    hasValue(allowedActions) ? allowedActions.includes(action) : false

export const isEditableEntity = hasAllowedAction(AllowedAction.enum.EDIT)

export const isDeletableEntity = hasAllowedAction(AllowedAction.enum.DELETE)

export const emptyTranslatedString = (): TranslatedString => ({
  eng: '',
  est: '',
  rus: ''
})

export const undefinedTranslatedString = (): Partial<TranslatedString> => ({
  eng: undefined,
  est: undefined,
  rus: undefined
})
